import Dapp from '@/dapp-lib-js/index'
import networks from '@/dapp-lib-js/networks'
import storage from '@/plugins/store/storage'
import BigNumber from 'bignumber.js'
import Vue from 'vue'
import VueMatomo from 'vue-matomo'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import './plugins/element.js'
import routes from './router/router'

BigNumber.set({ ROUNDING_MODE: BigNumber.ROUND_DOWN })

Vue.use(VueRouter)
Vue.use(Vuex)

let contractConfigs = {
  eth: {
    nftOne: '0xd49dd68c2fe68ca9f2aa449bf78616ff82db72a7',
    nftTwo: '',
    token: '',
  },
  eth_test_rinkeby: {
    nftOne: '',
    nftTwo: '',
    token: '',
  }
}

window.networkId = 'eth'
// window.networkId = 'eth_test_rinkeby'
window.contractConfig = contractConfigs[window.networkId]
window.window.dapp = new Dapp({ extension: 'MetaMask' })
window.openSeaGen1Link = 'https://opensea.io/collection/dirty-flies'
window.openSeaGen2Link = 'https://opensea.io/collection/human-fly'

const WALLET_CONNECT_STATUS_CHANGED = 'WALLET_CONNECT_STATUS_CHANGED'
const state = {
  walletConnectStatus: {
    address: '',
    isTargetNetwork: false,
    connected: false,
  },
}
const actions = {
  // eslint-disable-next-line no-shadow
  [WALLET_CONNECT_STATUS_CHANGED]: ({ state }) => {
    let currentChainId = window.dapp.network.chainId
    let findRet = networks.find((network) => network.chainId === currentChainId)
    storage.setItem('last_connected_network_id', findRet ? findRet.id : '')

    let address = window.dapp.currentAccount.address
    let isTargetNetwork = findRet ? findRet.id === window.networkId : false
    let connected = !!address
    if (connected) {
      if (state.walletConnectStatus.address !== address ||
        state.walletConnectStatus.isTargetNetwork !== isTargetNetwork ||
        state.walletConnectStatus.connected !== connected) {
        state.walletConnectStatus = { address, isTargetNetwork, connected }
      }
    } else {
      state.walletConnectStatus = { address: '', isTargetNetwork: false, connected }
    }
  },
}

const store = new Vuex.Store({
  modules: {
    WalletConnect: {
      state,
      actions,
    },
  },
})
Vue.$store = store
Vue.prototype.$store = store

window.addEventListener('load', async () => {
  // Modern dapp browsers...
  let dapp = window.dapp

  dapp.onEnabled(() => {
    store.dispatch(WALLET_CONNECT_STATUS_CHANGED)
  })

  dapp.onNetworkChanged(() => {
    store.dispatch(WALLET_CONNECT_STATUS_CHANGED)
  })

  dapp.onAccountChanged(() => {
    store.dispatch(WALLET_CONNECT_STATUS_CHANGED)
  })

  dapp.onDisconnect(() => {
    store.dispatch(WALLET_CONNECT_STATUS_CHANGED)
  })

  let inStoreNetworkId = storage.getItem('last_connected_network_id')
  if (window.networkId === inStoreNetworkId) {
    await dapp.enableBrowserExtension(inStoreNetworkId)
  }
})

const router = new VueRouter({
  mode: 'history',
  //控制滚动位置
  scrollBehavior(to, from, savedPosition) {
    //判断如果滚动条的位置存在直接返回到当前位置，否者返回到起点
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        return { selector: to.hash, behavior: 'smooth' }
      }
    }
  },
  routes,
})

const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
router.beforeEach(async (to, from, next) => {
  if (isMobile && to.meta.mobileWeb) {
    next({ name: to.meta.mobileWeb })
  } else if (!isMobile && to.meta.pcWeb) {
    next({ name: to.meta.pcWeb })
  } else {
    next()
  }
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
